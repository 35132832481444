import { useState, useRef, useEffect } from 'react';
import { Icon, Tab, TabList as TabListComponent } from '@dealroadshow/uikit';
import { ITabList } from '@/ui/shared/components/Landing/Sections/Tabs/interfaces';
import styles from './tabList.scss';

interface IProps {
  activeTab: string | number,
  tabs: ITabList[],
}

const TabList = ({ activeTab, tabs }: IProps) => {
  const [sliderPosition, setSliderPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const activeTabSelector = containerRef.current.querySelector(`[data-test="${ activeTab }Tab"]`);
      const containerRect = containerRef.current.getBoundingClientRect();
      const activeTabRect = activeTabSelector.getBoundingClientRect();
      const leftPosition = activeTabRect.left + activeTabRect.width / 2 - containerRect.left;
      setSliderPosition(leftPosition);
    }
  }, [activeTab]);

  const tabListContent = tabs.map(({ key, icon, label }) => (
    <Tab
      key={ key }
      tabFor={ key }
      className={ styles.tabItem }
      activeClassName={ styles.isTabActive }
      selected={ key === activeTab }
      name={ `${ key }Tab` }
    >
      <Icon
        type={ icon }
        className={ styles.tabItemIcon }
      />
      <div className={ styles.tabLabel }>{ label }</div>
    </Tab>
  ));

  return (
    <TabListComponent className={ styles.tabsList }>
      <div className={ styles.tabsWrp } ref={ containerRef }>
        { tabListContent }
        <div
          className={ styles.slider }
          style={ sliderPosition ? { left: `${ sliderPosition }px` } : { display: 'none' } }
        />
      </div>
    </TabListComponent>
  );
};

export default TabList;
